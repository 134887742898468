import React from 'react'
import Swal from 'sweetalert2'
import { AwesomeButton } from 'react-awesome-button'
import styled from 'styled-components'
import { number } from 'prop-types';


const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Container = styled.div`
  padding: 20px 10px;
`
const Item = styled.div`
  padding: 15px 0;
`
const Label = styled.p`
  display: block;
  font-size: .75rem;
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 1.45;
  text-transform: uppercase;
  margin-bottom: .3rem;
`
const Submit = styled(AwesomeButton)`
  width: 140px !important;
`

class ContactForm extends React.Component {
  state = {
    companyname: "",
    name: "",
    tel: "",
    email: "",
    subject: "",
    message: ""
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: {
         "Content-Type": "application/x-www-form-urlencoded"
      },
      body: encode({
        "form-name": "contact",
        ...this.state
      })
    })
    .then(() => {
      Swal({
        type: 'success',
        title: 'メッセージ送信完了',
        text: 'お問い合わせありがとうございました。こちらから改めてご連絡差し上げます。今しばらくお待ちください。',
        confirmButtonClass:  'Btn',
          cancelButtonClass:  'Btn',
          onClose: () => {
          this.setState({
            companyname: "",
            name: "",
            tel: "",
            email: "",
            subject: "",
            message: ""
          })
          }
      })

    })
    .catch(error => alert("Error sending the messag"))
    e.preventDefault()
  }

  handleChange = e => this.setState({
    [e.target.name]: e.target.value
  })

  render() {
    const { companyname,name,tel, email, subject, message } = this.state
    return (
      <Container>
        {/* <form
          name="contact"
          onSubmit={ this.handleSubmit }
          > */}
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />

        
          <Item>
            <Label>会社名（必須）
            </Label>
            <input name="companyname" value={companyname} placeholder="株式会社ソフトスタジオ" type="text" required
              onChange={this.handleChange} />
          </Item>
          <Item>
            <Label>お名前（必須）</Label>
            <input name="name" value={name} placeholder="呉島　進" type="text" required onChange={this.handleChange} />
          </Item>
          <Item>
            <Label>電話番号（必須）</Label>
            <input name="tel" value={tel} placeholder="09011112222" type="tel" required onChange={this.handleChange} />
          </Item>
          <Item>
            <Label>メールアドレス（必須）</Label>
            <input name="email" value={email} placeholder="your@email.com" type="email" required onChange={ this.handleChange }/>
          </Item>
          <Item>
            <Label>題名（必須）</Label>
            <input name="subject" value={subject} placeholder="社内のクラウド活用について" type="text" required onChange={ this.handleChange }/>
          </Item>
          <Item>
            <Label>内容（必須）</Label>
            <textarea name="message" value={message} placeholder="内容をお書きください" rows="6" required onChange={ this.handleChange }></textarea>
          </Item>
          <Item>
            <Submit
              size="medium"
              type="primary">
              送信
            </Submit>
          </Item>
        </form>
      </Container>
    )
  }
}

export default ContactForm
