import React from 'react'
import { graphql } from "gatsby"
import get from 'lodash/get'
import styled from 'styled-components'

import ContactForm from '../components/ContactForm'
import ContactSocial from '../components/ContactSocial'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Header from '../components/Header'
import { pages } from '../utils'

const ContactFormWrapper = styled.div`
	max-width: 690px;
	margin: 0 auto;
`

class Contact extends React.Component
{
	render()
	{
		const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
		return (
			<Layout location={this.props.location} active={pages.contact}>
				<div className="Contact">
					<SEO
						title="お問い合わせ"
						url={`${siteUrl}/contact`}
					/>
					<Header
						title='お問い合わせ'
						description={['今すぐ、IT導入しないと、ゆくゆく、あなたの会社は競合他社と圧倒的な差をつけられてしまいます。', <br />, 'メールで直接お問い合わせの場合は ', <b>info@softstudio.co.jp</b>, <br />,
							'お電話でのお問い合わせは　', <b><a href="tel:05035625099">050-3562-5099</a></b>,]}
						color='#61b865'
						bottom='24px' />
					<ContactSocial />
					<ContactFormWrapper>
						<ContactForm />
					</ContactFormWrapper>
				</div>
			</Layout>
		)
	}
}

export const queryContact = graphql`
  	query QueryContact {
    	site {
      	siteMetadata {
         	title
         	siteUrl
      	}
    	}
  	}
`

export default Contact
